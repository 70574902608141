import styled from "styled-components";

export const ResgistroContainer = styled.div`
  border: 2px solid #464646;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 1rem;
`

export const ResgistroContainerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`