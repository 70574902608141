import styled from 'styled-components';

export const MyRoot = styled.div`
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export const MyAccordionItem = styled.div`
    overflow: hidden;
    margin-top: 1px;

    &:first-child {
        margin-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    &:focus-within {
        position: relative;
        z-index: 1;
    }
`
export const MyHeader = styled.h3`
    all: unset;
    display: flex;
`

export const MyTrigger = styled.button`
    all: unset;
    font-family: inherit;
    background-color: transparent;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    gap: 1rem;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 1;
    color: black;
    background-color: ${props => props.$bg ?? '#faf9fb'};
    cursor: pointer;

    &:hover {
        background-color: ${props => props.$bgHover ?? '#efefef'};

    }

    svg{
        transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    &[data-state='open'] {
        color: white;
        background-color: #18181b;
        svg{
            transform: rotate(180deg);
        }
    }
`

export const MyContent = styled.div`
    overflow: hidden;
    font-size: 15px;
    color: #65636d;
    display: flex;
    flex-direction: column;
    gap: 3px;

    &[data-state='open'] {
        animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    &[data-state='closed'] {
        animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
`