import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: start;
justify-content: start;
width: 100%;
gap: 0.5rem;
//estiliza o placeholder
> textarea{
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #464646;
  border-radius: 5px;
  height: 10rem;
  max-width: 100%;
  &::placeholder{
    font-size: 0.85rem;
  }
}
`