import { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { api } from "../services/api/axios";
import { UsuarioService } from "../pages/Login/services/UsuarioService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) =>{
    const [usuario, setUsuario] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAutenticado, setIsAutenticado] = useState(false)

    const autenticado = async() => {
        try {
            const tokenStoraged = localStorage.getItem('@DF:token');
            if (!tokenStoraged) {
                return false;
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${tokenStoraged}`;
            const response = await UsuarioService.autenticar();
            if (response?.status !== 200) {
                localStorage.removeItem("@DF:user");
                localStorage.removeItem("@DF:token");
                return false;
            }
            return true;
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    };

    useEffect(() => {
        const auth = async () =>{
            const userStoraged = localStorage.getItem('@DF:user');
            const userAutenticado = await autenticado();
            if(userAutenticado){
                setUsuario(JSON.parse(userStoraged));
            }else{
                setUsuario(null);
            }
            setIsAutenticado(userAutenticado);
            setLoading(false);
        }

        auth();
    }, []);

    const login = async (username, password) =>{
        try {
            const response = await UsuarioService.login(username, password);
            const user = {
                nome: response.nome,
            }
            const token = response.token;
            setUsuario(user);
            setIsAutenticado(true)
            localStorage.setItem('@DF:user', JSON.stringify(user));
            localStorage.setItem('@DF:token', token);
            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            return true;
        } catch (error) {
            throw error;
        }
    }

    const logout = () =>{
        setUsuario(null);
        setIsAutenticado(false)
        localStorage.removeItem('@DF:user');
        localStorage.removeItem('@DF:token');
        api.defaults.headers.common["Authorization"] = undefined;
        window.location.href = '/login'
    }

    return (
        <AuthContext.Provider value={{
            login,
            logout,
            usuario,
            loading,
            logado: !!usuario,
            autenticado,
            isAutenticado
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);