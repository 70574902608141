import { useState } from 'react';
import { Button, Title } from '../../styles/shared';
import { InputField, LoginContainer, LoginForm } from './styles';
import { useAuth } from '../../hooks/auth';
import { Toaster, toast } from 'sonner';
import Loading from '../../components/Loading';

const Login = () => {
    const {logado, login, loading} = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        
        try {
            await login(username, password);
        } catch (error) {
            toast.error('Usuário ou senha inválidos');
            // console.error('Error:', error);
        }
    };

    if(loading){
        return <Loading />;
    }else if(logado){
        window.location.href = '/';
    }

    return (
        <LoginContainer>
            <LoginForm onSubmit={handleLogin}>
                <Title>Document Forge</Title>
                <InputField
                type="email"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                />
                <InputField
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                />
                <Button type="submit">Login</Button>
            </LoginForm>
            <Toaster position="top-right" richColors duration={1000 * 2.5}/>
        </LoginContainer>
    );
};

export default Login;