import { Container } from './styles';

const Input = ({ label, type, placeholder, ...res }) => {
    return (
        <Container >
            <label htmlFor="">{label}</label>
            <input type={type} placeholder={placeholder} {...res} />
        </Container>
    )
}

export default Input;
