import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';

import{
    MyRoot,
    MyAccordionItem,
    MyHeader,
    MyTrigger,
    MyContent
} from './styles';


export const AccordionRoot = ({children, ...props}) => (
    <Accordion.Root asChild type="single" collapsible {...props}>
        <MyRoot>
            {children}
        </MyRoot>
    </Accordion.Root>
)

export const AccordionItem = ({children, value,...props}) => (
    <Accordion.Item asChild value={value} {...props}>
        <MyAccordionItem>
            {children}
        </MyAccordionItem>
    </Accordion.Item>
)

export const AccordionHeader = ({children, ...props}) => (
    <Accordion.Header asChild {...props}>
        <MyHeader>
            {children}
        </MyHeader>
    </Accordion.Header>
)

export const AccordionTrigger = ({children, ...props}) => (
    <Accordion.Trigger asChild>
        <MyTrigger {...props}>
            <ChevronDownIcon />
            {children}
        </MyTrigger>
    </Accordion.Trigger>
)

export const AccordionContent = ({children, ...props}) => (
    <Accordion.Content asChild {...props}>
        <MyContent>
            {children}
        </MyContent>
    </Accordion.Content>
)