import { Container } from './styles';

const TextArea = ({ label, placeholder, ...res }) => {
    return (
        <Container >
            <label htmlFor="">{label}</label>
            <textarea placeholder={placeholder} {...res} />
        </Container>
    )
}

export default TextArea;
