import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Registro from '../pages/Registro';
import Login from '../pages/Login';
import ProtectecLayout from '../layouts/ProtectedLayout';
import Crachas from '../pages/Crachas';
const Rotas = () => {

    return (
        <Routes>
            <Route path="/" element={<ProtectecLayout><Home /></ProtectecLayout>} />
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<ProtectecLayout><Registro /></ProtectecLayout>} />
            <Route path="/crachas" element={<ProtectecLayout><Crachas /></ProtectecLayout>} />
        </Routes>
    );

}

export default Rotas;