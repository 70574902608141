import React from 'react'
import { LoadingContainer, Spinner } from './styles'

function Loading() {
  return (
    <LoadingContainer>
        <Spinner />
        <p>Carregando...</p>
    </LoadingContainer>
  )
}

export default Loading