import { api } from "../../../../services/api/axios";

export class CrachaApi {
    static async generateCrachas(formData) {
        try {
            const response = await api.post('/cracha', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            });
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}