import { api } from "../../../services/api/axios";

export class modeloApi {
    static async getModelos() {
        try {
            const response = await api.get("/modelo-documento");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    static async getModeloById(id) {
        try {
            const response = await api.get(`/modelo-documento/${id}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    static async downloadExample(formData){
        try {
            const response = await api.post('/documento-exemplo', formData, { responseType: 'blob' })
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async downloadAll(formData){
        try {
            const response = await api.post('/documento/download', formData, { responseType: 'blob' })
            return response;
        } catch (error) {
            throw new Error(error);
        }
    }

    static async downloadRecibos(formData){
        try {
            const response = await api.post('/recibos', formData, { responseType: 'blob' })
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async downloadRegistroById(id){
        try {
            const response = await api.get(`/documento/download/${id}`, { responseType: 'blob' })
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async sendEmails(formData){
        try {
            const response = await api.post('/documento', formData)
            return response;
        } catch (error) {
            
        }
    }

    static async resendEmail(id){
        try {
            const response = await api.get(`/documento/reenviar/${id}`)
            return response;
        } catch (error) {
            throw new Error(error);
        }
    }

    static async createModelo(formData){
        try {
            const response = await api.post('/modelo-documento', formData)
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async updateModelo(formData, id){
        try {
            const response = await api.put(`/modelo-documento/${id}`, formData)
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteModelo(id){
        try {
            const response = await api.delete(`/modelo-documento/${id}`)
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async getRegistros() {
        try {
            const response = await api.get("/registro");
            return response;
        } catch (error) {
            console.error(error);
        }
    }
}