import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;
`;

export const ContainerCollumContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const BoxForm = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  padding: 2rem;
  font-weight: bold;
  gap: 1rem;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  gap: 1rem;
  margin-bottom: 3px;
`;

export const BoxContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const SelectsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const SelectsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Select = styled.select`
  width: 100%;
  height: 2.5;
  background-color: white;
  border: 1px solid #464646;
  border-radius: 3px;
  padding: 0.5rem;
`;

export const SelectContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const Option = styled.option`
  width: 100%;
  height: 2rem;
  border: 1px solid #464646;
  border-radius: 3px;
  padding: 0.5rem;
`;

export const Button = styled.button`
  color: white;
  background-color: ${props => props.$bg ?? '#18181b'};
  border: none;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px 16px;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    opacity: 0.9;
  }

  svg{
    height: 24px;
    width: 24px;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: #464646;
  font-weight: bold;
  text-align: center;
`;

export const BoxFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;

export const FooterItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
`