import { UsuarioApi } from "./api/UsuarioApi";

export class UsuarioService {
    static async login(username, password) {
        try {
            const response = await UsuarioApi.login(username, password);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async autenticar() {
        try {
            const response = await UsuarioApi.autenticar();
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}