import { BrowserRouter } from 'react-router-dom';
import Rotas from './routes/index';
import GlobalStyles from './styles/global'
import { AuthProvider } from './hooks/auth';
function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Rotas />
        <GlobalStyles />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
