import { CrachaApi } from "./api/CrachaApi";

export class CrachaService {
    static async generateCrachas(formData) {
        try {
            const response = await CrachaApi.generateCrachas(formData);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}