import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/auth';
import Loading from '../components/Loading';

function ProtectecLayout({children}) {
    const {isAutenticado, loading} = useAuth();

    if (loading) {
        return <Loading />;
    }else{
        if(isAutenticado){
            return children
        }
        return <Navigate to='/login' />
    }
}

export default ProtectecLayout