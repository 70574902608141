import styled from 'styled-components'

export const ImageContent = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid black;
    align-items: center;
`

export const Image = styled.img`
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: center;

    @media (max-width: 576px) {
        width: 100px;
        height: 100px;
    }
`