import { useState, useRef } from "react";
import{
    Container,
    ContainerRow,
    ContainerCollumContent,
    BoxForm,
    Title,
    Button,
    SelectContent,
    Select,
    Option,
    BoxContent,
    FooterItem,
    BoxFooter
} from "../../styles/shared";

import{
    PlusCircle,
    Trash,
    FileArrowDown,
    SignOut,
    ArrowCircleLeft
} from "@phosphor-icons/react";

import Input from "../../components/Input";
import { CrachaService } from "./services/CrachaService";
import { Toaster, toast } from 'sonner'
import { Image, ImageContent } from "./styles";
import { useAuth } from "../../hooks/auth";

const Crachas = () => {
    const [imagens, setImagens] = useState([])
    const fileInputRef = useRef(null)
    const selectEmpresaRef = useRef(null)
    const { logout } = useAuth();

    const handleUpload = (e) => {
        const files = e.target.files;
        const newImages = [];
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            newImages.push(file);
        }
        setImagens(prevState => [...prevState, ...newImages]);
        fileInputRef.current.value = '';
        if(newImages.length > 1) {
            toast.success('Imagens adicionadas com sucesso!')
        }else{
            toast.success('Imagem adicionada com sucesso!')
        }
    }

    const handleRemove = (index) => {
        setImagens(prevState => {
            const newState = prevState.filter((_, i) => i !== index)
            if(newState.length === 0) {
                fileInputRef.current.value = '';
            }
            return newState;
        });
        toast.success('Imagem removida com sucesso!')
    }

    const handleGenerate = async (e) => {
        e.preventDefault()

        if(!sendValidate()){
            toast.error('Preencha todos os campos!')
            return  
        }

        const json = {};
        let isValid = true;

        imagens.forEach((imagem, index) => {
            const nome = document.getElementById(`nome-${index}`).value;
            const cargo = document.getElementById(`cargo-${index}`).value;
            if(!nome || !cargo) {
                isValid = false;
                return;
            }
            const foto = imagem.name;
            json[index + 1] = { nome, cargo, foto };
        });
        
        if(!isValid){
            toast.error('Preencha todos os campos!')
            return;
        }

        const formData = new FormData();
        for(let i = 0; i < imagens.length; i++) {
            formData.append('imagens', imagens[i]);
        }
        formData.append('nomeCargo', JSON.stringify(json));
        formData.append('empresa', selectEmpresaRef.current.value);
        try {
            const response = await CrachaService.generateCrachas(formData);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'crachas.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    const sendValidate = () => {
        if(selectEmpresaRef.current.value === '0' || imagens.length === 0) {
            return false;
        }

        return true;
    }

    return (
        <Container>
            <ContainerRow>
                <ContainerCollumContent>
                    <BoxForm>
                        <Title style={{marginBottom: '1rem'}}>Crachás</Title>
                        <BoxFooter>
                            <FooterItem>
                                <Button type="button" onClick={() => fileInputRef.current.click()}>
                                    <PlusCircle />
                                    Adicionar foto
                                </Button>
                            </FooterItem>
                            <FooterItem>
                                
                                <Button onClick={() => window.location.href = '/'}>
                                    <ArrowCircleLeft />
                                    Voltar
                                </Button>
                                <Button type='button'onClick={logout}>
                                    <SignOut />
                                    Logout
                                </Button>
                            </FooterItem>
                        </BoxFooter>
                            <form onSubmit={handleGenerate}>
                                <input ref={fileInputRef} hidden type="file" accept="image/*" multiple onChange={e => handleUpload(e)}/>
                                <BoxContent>
                                
                                {imagens?.map((imagem, index) => {
                                    return (
                                        <ImageContent key={index}>
                                            <Image src={URL.createObjectURL(imagem)} alt="Imagem"/>
                                            {/* <div style={{ backgroundImage: `url("${URL.createObjectURL(imagem)}")`, width: '200px', height: '200px', backgroundSize: 'cover', backgroundPosition: 'center' }}></div>                                    */}
                                            {/* <Image $imageSrc={URL.createObjectURL(imagem)}></Image> */}
                                            <BoxContent>
                                                <Input id={`nome-${index}`} type="text" label="Nome"/>
                                                <Input id={`cargo-${index}`} type="text" label="Cargo"/>
                                                <div style={{display: 'flex', justifyContent: 'end'}}>
                                                    <Button $bg="red" type="button" onClick={() => handleRemove(index)}>
                                                        <Trash />
                                                        Remover
                                                    </Button>
                                                </div>
                                            </BoxContent>
                                        </ImageContent>
                                    )
                                })}
                                <SelectContent>
                                    <label htmlFor="">Empresa</label>
                                    <Select ref={selectEmpresaRef}>
                                        <Option value="0">Selecione a empresa</Option>
                                        <Option value="1">El Dorado</Option>
                                        <Option value="2">Center 1</Option>
                                    </Select>
                                </SelectContent>
                                <div style={{display: 'flex', justifyContent: 'start'}}>
                                    <Button onSubmit={e => handleGenerate(e)}>
                                        <FileArrowDown />
                                        Baixar
                                    </Button>
                                </div>
                                </BoxContent>                        
                            </form>
                    </BoxForm>
                </ContainerCollumContent>
            </ContainerRow>
            <Toaster position="top-right" richColors duration={1000 * 2.5}/>
        </Container>
    );
}

export default Crachas;