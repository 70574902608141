import { api } from "../../../../services/api/axios";

export class UsuarioApi {
    static async login(username, password) {
        try {
            const response = await api.post("/login", {
                emailUsuario: username,
	            senhaUsuario: password
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async autenticar() {
        try {
            const response = await api.get("/authenticate");
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}