import{
    P
} from './styles';

import {
    Container,
    ContainerCollumContent,
    ContainerRow,
    BoxForm,
    BoxContent,
    BoxFooter,
    Title,
    SelectsRow,
    Select,
    Option,
    FooterItem,
    SelectContent,
    BoxContentRow,
    SelectsDiv
} from '../../styles/shared';

import { Button } from '../../styles/shared';

import{
    FileArrowDown,
    PaperPlaneRight,
    FilePlus,
    CheckSquare,
    Trash,
    Files,
    SignOut,
    IdentificationCard,
    FileArchive,
    BoxArrowDown
} from "@phosphor-icons/react";

import { Toaster, toast } from 'sonner'

import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import { useEffect, useState } from 'react';
import { modeloService } from './services/modeloService';
import { useAuth } from '../../hooks/auth';



const Home = () => {
    const [titulo, setTitulo] = useState('');
    const [corpo, setCorpo] = useState('');
    const [assinante, setAssinante] = useState('');
    const [localData, setLocalData] = useState('');
    const [assunto, setAssunto] = useState('');
    const [corpoEmail, setCorpoEmail] = useState('');
    const [arquivo, setArquivo] = useState(null);
    const [setor, setSetor] = useState('0');
    const [modelos, setModelos] = useState([]);
    const [modelIdSelected, setModelIdSelected] = useState(null);
    const [selectValue, setSelectValue] = useState('0');
    const [emailCopia, setEmailCopia] = useState('');
    const [empresa, setEmpresa] = useState('0');
    const { logout } = useAuth();
    
    const emails = [
        "rh@center1hotel.com.br",
        "rh@eldoradohotel.tur.br",
        "rh@okintercambio.com.br" 
    ]


    const loadModelos = async () => {
        const respModelos = await modeloService.getModelos();
        setModelos(respModelos);
    }

    useEffect(() => {
        loadModelos()
    }, []);

    const handleLoadModeloSelected = async (id) => {
        if (id !== '0') {
            setModelIdSelected(id);
            setSelectValue(id);
            const respModelo = await modeloService.getModeloById(id);
            setTitulo(respModelo.titulo_documento);
            setCorpo(respModelo.corpo_documento);
            setAssinante(respModelo.assinante_documento);
            setLocalData(respModelo.local_data_documento);
            setAssunto(respModelo.assunto_email_documento);
            setCorpoEmail(respModelo.corpo_email_documento);
            setSetor(respModelo.setor_documento);
            setEmpresa(respModelo.empresa_documento);
            if (respModelo.copia_documento && respModelo.copia_documento !== '') {
                setEmailCopia(respModelo.copia_documento);
            }else{
                setEmailCopia('0');
            }
        }else{
            setModelIdSelected(null);
            setSelectValue('0');
            setTitulo('');
            setCorpo('');
            setAssinante('');
            setLocalData('');
            setAssunto('');
            setCorpoEmail('');
            setSetor('0');
            setEmpresa('0');
            setEmailCopia('0');
        }
    }

    const handleSubmit = async () => {
        if (!fullValidate()) {
            return
        }
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        formData.append('tipo', setor);

        formData.append('titulo', titulo);
        formData.append('corpo', corpo);
        formData.append('assinante', assinante);
        formData.append('localData', localData);
        formData.append('assunto', assunto);
        formData.append('corpoEmail', corpoEmail);
        formData.append('empresa', empresa);
        formData.append('copia', emailCopia !== '0' ? emailCopia : null);

        // console.log(values);

        try {
            await modeloService.sendEmails(formData);
            toast.success('Os emails estão sendo enviados!');
            setTitulo('');
            setCorpo('');
            setAssinante('');
            setLocalData('');
            setAssunto('');
            setCorpoEmail('');
            setSetor('0');
            setSelectValue('0');
            setEmpresa('0');
            setEmailCopia('0');
        } catch (error) {
            toast.error('Erro ao enviar os emails!');
        }
        
    };

    const handleAddModel = async () => {
        if (!editValidate()) {
            return
        }
        
        const values = {
            tituloDocumento: titulo,
            corpoDocumento: corpo,
            assinanteDocumento: assinante,
            localDataDocumento: localData,
            assuntoEmail: assunto,
            corpoEmail: corpoEmail,
            setorDocumento: setor,
            empresaDocumento: empresa,
            copiaDocumento: emailCopia !== '0' ? emailCopia : null
        }

        try {
            await modeloService.createModelo(values);
            toast.success('Modelo adicionado com sucesso!');
        } catch (error) {
            console.log(error);
        }
    }

    const handleEditModel = async () => {
        if (!editValidate()) {
            return
        }

        const values = {
            tituloDocumento: titulo,
            corpoDocumento: corpo,
            assinanteDocumento: assinante,
            localDataDocumento: localData,
            assuntoEmail: assunto,
            corpoEmail: corpoEmail,
            setorDocumento: setor,
            empresaDocumento: empresa,
            copiaDocumento: emailCopia !== '0' ? emailCopia : null
        }

        try{
            await modeloService.updateModelo(values, modelIdSelected);
            toast.success('Modelo atualizado com sucesso!');
        }catch(error){
            console.log(error);
        }
    }

    const handleDeleteModel = async () => {
        const agreed = window.confirm('Deseja realmente deletar o modelo?');
        try {
            if (agreed) {
                await modeloService.deleteModelo(modelIdSelected);
                toast.success('Modelo deletado com sucesso!');
                setTitulo('');
                setCorpo('');
                setAssinante('');
                setLocalData('');
                setAssunto('');
                setCorpoEmail('');
                setSetor('0');
                setSelectValue('0');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fullValidate = () => {
        //checa se os campos estão preenchidos
        if (titulo === '' || corpo === '' || assinante === '' || localData === '' || assunto === '' || corpoEmail === '' || arquivo === null || setor === '0' || empresa === '0') {
            toast.error('Preencha todos os campos!');
            return false;
        } else {
            return true;
        }
    };

    const downloadValidate = () => {
        //checa se os campos estão preenchidos, menos o setor, corpoEmail e assunto
        if (titulo === '' || corpo === '' || assinante === '' || localData === '' || arquivo === null || empresa === '0') {
            toast.error('Preencha todos os campos!');
            return false;
        } else {
            return true;
        }
    };

    const editValidate = () => {
        //checa se os campos estão preenchidos, menos o setor e arquivo
        if (titulo === '' || corpo === '' || assinante === '' || localData === '' || assunto === '' || corpoEmail === '') {
            toast.error('Preencha todos os campos!');
            return false;
        } else {
            return true;
        }
    }


    const handleDownload =  async () => {
        if (!downloadValidate()) {
            return
        }
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        formData.append('titulo', titulo);
        formData.append('corpo', corpo);
        formData.append('assinante', assinante);
        formData.append('localData', localData);
        formData.append('empresa', empresa);
        try {
            const res = await modeloService.downloadExample(formData);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exemplo.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.error('Erro ao baixar o arquivo!');
            console.log(error);
        }

    };

    const handleDownloadAll =  async () => {
        if (!downloadValidate()) {
            return
        }
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        formData.append('titulo', titulo);
        formData.append('corpo', corpo);
        formData.append('assinante', assinante);
        formData.append('localData', localData);
        formData.append('empresa', empresa);

        try {
            const res = await modeloService.downloadAll(formData);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exemplo_todos.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.error('Erro ao baixar o arquivo!');
            console.log(error);
        }
    }

    const handleDownloadRecibos =  async () => {
        if (!downloadValidate()) {
            return
        }
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        formData.append('titulo', titulo);
        formData.append('corpo', corpo);
        formData.append('assinante', assinante);
        formData.append('localData', localData);
        formData.append('empresa', empresa);

        //baixar o arquivo da requisição
        try {
            const res = await modeloService.downloadRecibos(formData);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'recibos.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container>
            <ContainerRow>
                <ContainerCollumContent>
                    <BoxForm>
                        <Title style={{marginBottom: '1rem'}}>Document Forge</Title>
                        <SelectsDiv>
                            <FooterItem style={{alignSelf: 'end'}}>
                                <Button onClick={() => window.location.href = '/crachas'}>
                                    <IdentificationCard />
                                    Crachás
                                </Button>
                                <Button onClick={() => window.location.href = '/registro'}>
                                    <Files />
                                    Registros de Envio
                                </Button>
                                <Button onClick={logout}>
                                    <SignOut />
                                    Logout
                                </Button>
                            </FooterItem>
                                
                            <SelectsRow>
                                <SelectContent>
                                    <label htmlFor="">Modelo</label>
                                    <Select label="Modelo" value={selectValue} onChange={(e) => handleLoadModeloSelected(e.target.value)}>
                                        <Option value="0">Selecione o modelo</Option>
                                        {modelos?.map((modelo) => {
                                            return (
                                                <Option
                                                key={modelo.id_documento}
                                                value={modelo.id_documento}>
                                                    {modelo.titulo_documento}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </SelectContent>
                                <SelectContent>
                                    <label htmlFor="">Setor</label>
                                    <Select label="Setor" value={setor} onChange={(e) => setSetor(e.target.value)}>
                                        <Option value="0">Selecione o setor</Option>
                                        <Option value="1">Financeiro</Option>
                                        <Option value="2">Recursos Humanos</Option>
                                    </Select>
                                </SelectContent>
                                
                            </SelectsRow>
                        </SelectsDiv>
                        
                        <BoxContent>
                            <P>Dados referentes ao PDF</P>
                            <Input value={titulo} label="Título do PDF" type="text" placeholder="Ex: Recibo de Vale Refeição" onChange={(e) => setTitulo(e.target.value)} />
                            <TextArea value={corpo} label="Corpo do PDF" type="text" placeholder="Ex: Eu <<nome>>, inscrito(a) no CPF nº. <<CPF>>..." onChange={(e) => setCorpo(e.target.value)} />
                            <BoxContentRow>
                                <Input value={assinante} label="Assinante" type="text" placeholder="Ex: Estagiário" onChange={(e) => setAssinante(e.target.value)} />
                                <Input value={localData} label="Local e Data" type="text" placeholder="Ex: Fortaleza/CE, 12 de setembro de 2023" onChange={(e) => setLocalData(e.target.value)} />
                            </BoxContentRow>
                            <Input
                                label="Arquivo"
                                type="file"
                                onChange={(e) => setArquivo(e.target.files[0])}
                                accept=".xls,.xlsx,.ods"
                            />
                        </BoxContent>
                        <BoxContent>
                            <P>Dados referentes ao Email</P>
                            <Input value={assunto} label="Assunto" type="text" placeholder="Ex: Recibo de Vale Refeição" onChange={(e) => setAssunto(e.target.value)} />
                            <TextArea value={corpoEmail} label="Corpo do Email" type="text" placeholder="Ex: Por favor <<nome>>, devolver assinado em até 3 dias..." onChange={(e) => setCorpoEmail(e.target.value)} />
                            <SelectsDiv>
                                <SelectsRow>
                                    <SelectContent>
                                        <label htmlFor="">Email para cópia</label>
                                        <Select value={emailCopia} onChange={(e) => setEmailCopia(e.target.value)}>
                                            <Option value="0">Não enviar cópia</Option>
                                            {emails?.map((email) => {
                                                return (
                                                    <Option
                                                    key={email}
                                                    value={email}>
                                                        {email}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </SelectContent>
                                    <SelectContent>
                                        <label htmlFor="">Empresa</label>
                                        <Select value={empresa} onChange={(e) => setEmpresa(e.target.value)}>
                                            <Option value="0">Selecione a empresa</Option>
                                            <Option value="1">JIT Technology</Option>
                                            <Option value="2">Center 1</Option>
                                            <Option value="3">El Dorado</Option>
                                            <Option value="4">OK Intercâmbio</Option>
                                        </Select>
                                    </SelectContent>
                                </SelectsRow>
                            </SelectsDiv>
                            
                        </BoxContent>
                        <BoxFooter>
                            <FooterItem>
                                {modelIdSelected && <Button onClick={handleEditModel} $bg='#677c9b'><CheckSquare/>Salvar Alterações</Button>}
                                {modelIdSelected && <Button onClick={handleDeleteModel} $bg='#ef4444'><Trash/>Exluir Modelo</Button>}
                                {!modelIdSelected && <Button onClick={handleAddModel} $bg='#677c9b'><FilePlus/>Adicionar modelo</Button>}
                            </FooterItem>
                        </BoxFooter>
                        <BoxFooter>
                            <FooterItem>
                                <Button onClick={handleDownloadRecibos} >
                                    <FileArchive />
                                    Baixar Recibos
                                </Button>
                                <Button onClick={handleDownloadAll}>
                                    <BoxArrowDown />
                                    Baixar todos
                                </Button>
                                <Button onClick={handleDownload} >
                                    <FileArrowDown />
                                    Baixar Exemplo
                                </Button>
                                <Button onClick={handleSubmit}>
                                    <PaperPlaneRight />
                                    Disparar Emails
                                </Button>
                            </FooterItem>
                        </BoxFooter>
                    </BoxForm>
                </ContainerCollumContent>
            </ContainerRow>
            <Toaster position="top-right" richColors duration={1000 * 2.5}/>
        </Container>
    );
};

export default Home;
